$primary: #3887db;
$secondary: #9aa1a9;
$background: #edf2f9;
$secondary-bg: #edf2f9;
$secondary-bg-lighter: #edf2f8;
$primary-text: #344050;
$text-secondary: #576b75;
$fade-text: #b6c2d2;
$tr-bg: #fafbfd;
$fade-text60: #f4f4f499;
$input-text: #5d6e82;
$secondary-text: #5d6e82;
$error: #e6405c;
$error-soft: #fcebee;
$success: #00864e;
$pending: #c46632;
$table-even: #f9fafd;
$table-td: #748194;

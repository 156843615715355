@import "./styles/colors";
@import "./styles/mixins";
.App {
  text-align: center;
}
canvas {
  border-radius: 6px !important;
}
.app-logo {
  height: 40vmin;
  pointer-events: none;
  background: linear-gradient(90deg, #03dac6 0.1%, #5d4bea 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.notification__item--danger {
  background-color: $error;
  // border-left: 8px solid $error-soft;
}
.notification__item--success {
  background-color: $success;
  // border-left: 8px solid $error-soft;
}
.notification__item--warning {
  background-color: $pending;
  // border-left: 8px solid $error-soft;
}

.__page__container {
  // min-height: 100vh;
  filter: drop-shadow(0px 7px 14px rgba(65, 69, 88, 0.1))
    drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.07));
  background: #fff;
  border-radius: 6px;
  position: relative;
}
.xeni-container {
  // padding: 40px 104px;
}
.-fs-12 {
  font-size: 12px;
}
.-fs-13 {
  font-size: 13px;
}
.-fs-28 {
  font-size: 28px !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $primary-text;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-weight: 700;
}
h1 {
  font-size: 32px;
}
h2 {
  font-size: 24px;
}
h3 {
  font-size: 20px;
}
h4 {
  font-size: 16px;
  font-weight: 600;
}
p,
a {
  color: #344050;
  margin: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.navbar-brand {
  font-weight: 700;
  font-size: 24px;
  color: #000000 !important;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.secondary-bg {
  background-color: #f4f4f4;
}
.text-primary {
  color: $primary;
}
.btn {
  border-radius: 3px;
  height: 36px;
  color: #ffffff;
}
.form-control,
.form-select {
  border-radius: 3px;
  border: 1px solid #e5e5e5;
  // background: #ffffff;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
  color: $secondary;
  option {
    color: $primary;
    font-size: 13px;
    font-weight: 500;
    padding: 5px 0;
  }
  &:focus {
    background-color: #fff;
    color: $primary-text;
  }
}
.-primary-background {
  background-color: $primary-text;
  border-color: $primary-text;
}

.input-group-text {
  border-radius: 3px;
  border-color: $background;
}
.form-label {
  color: $primary-text;
  font-weight: 500;
}
.form-check-input {
  border-radius: 0.2px;
}
.form-check-input:checked {
  background: $primary;
  border-color: $primary;
}
.text-bold {
  font-weight: 500;
}
.text-normal {
  font-weight: 400;
}
.-text-light {
  color: $fade-text;
}
.-text-dark {
  color: $primary;
}
.-text-fade {
  color: #b6c2d2;
}
.-text-underline {
  text-decoration: underline;
}
.-cp {
  cursor: pointer;
}
.btn {
  font-weight: 500;
  &:hover {
    color: #fff;
  }
}
.btn-outline-primary {
  font-weight: 500;
  border: 1px solid;
  &:hover {
    background: none !important;
    color: $primary;
  }
}
.btn-outline-secondary {
  color: $primary;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 1px 1.5px rgba(0, 0, 0, 0.07),
    0px 2px 5px rgba(43, 45, 80, 0.08), 0px 0px 0px 1px rgba(43, 45, 80, 0.1);
  &:hover {
    border-color: $background;
    color: $primary;
  }
}
.btn-primary {
  &:disabled {
    color: #ffffff;
  }
}
.btn-secondary {
  color: #748194 !important;
  background: #d8e2ee !important;
  background: #f9fafd;
  border: 1px solid #d8e2ee;
  box-sizing: border-box;
  border-radius: 4px;
}
.__user_icon {
  width: 32px;
  @include sm {
    width: 24px;
    height: 24px;
  }
}
.__menu_dropdown {
  width: 12px;
}

.modal-dialog {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border-radius: 8px;
  background: $secondary-bg;
  .modal-header {
    border: 0;
    .btn-close {
      font-size: 12px;
    }
  }
  .modal-content {
    background: none;
    border: 0;
    height: inherit;
  }
}
.__page_padding {
  padding: 0px 20px;
}
.__page_title {
  padding: 15px 20px;
  box-shadow: inset 0px -1px 0px rgba(102, 102, 102, 0.08);
  border-radius: 6px 6px 0px 0px;
  display: flex;
  align-items: center;
  margin-bottom: 22px;
  .__title {
    font-size: 20px;
    font-weight: 500;
    color: $primary-text;
  }
}
._hash {
  font-size: 13px;
  color: $primary;
  &:hover {
    text-decoration: underline;
  }
}
.-w-12 {
  width: 12px;
}
.-w-84 {
  width: 84px;
}
.-w-45 {
  width: 45%;
}
.__lite_text {
  font-weight: 400;
  font-size: 13px;
  padding: 8px 10px;
}
.-normal-text {
  font-size: 13px;
  font-weight: 500;
  color: $primary-text;
}
._medium-text {
  font-size: 16px;
  font-weight: 600;
  color: $secondary-text;
}
._medium-text-500 {
  font-size: 16px;
  font-weight: 500;
  color: $secondary-text;
}
.-secondary-text {
  color: $secondary-text;
  font-size: 13px;
  font-weight: 500;
}
.-secondary-text-normal {
  color: $secondary-text;
  font-size: 13px;
}
.bg-success {
  background: #1dc39a;
}
.bg-danger {
  background: #f41010;
}
.bg-warning {
  background: #f1cb44;
}
.-ml-2 {
  margin-left: 4px;
}
.-ml-3 {
  margin-left: 6px;
}
.-ml-4 {
  margin-left: 8px;
}
.-ml-5 {
  margin-left: 10px;
}
.-mr-2 {
  margin-right: 4px;
}
.-mr-3 {
  margin-right: 6px;
}
.-mr-4 {
  margin-right: 8px;
}
.-mr-5 {
  margin-right: 10px;
}
.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none;
  color: #fff;
}
input,
select {
  height: 36px;
}
.-text-secondary {
  color: #576b75;
}
th {
  text-align: center;
  vertical-align: middle;
}

.-text-bold {
  font-weight: 500;
}
.invalid-feedback {
  display: inline;
  color: $error;
}

.-cion-size {
  width: 32px;
  height: 32px;
  margin: 0 10px;
  @include sm {
    width: 24px;
    height: 24px;
    margin: 0 3px;
  }
}
._center {
  display: block;
  margin: auto;
}
._background_image {
  background-repeat: no-repeat;
  background-size: contain;
  height: 100vh;
  width: 100%;
  @include sm {
    display: none;
  }
}
.-medium-bold {
  font-size: 14px;
  font-weight: 500;
  color: $secondary-text;
}
.-medium-bold-black {
  font-size: 14px;
  font-weight: 500;
  color: $primary-text;
}
._text-small {
  font-size: 11.5px;
  font-weight: 500;
  color: $secondary-text;
}
.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: $error;
}
*:focus {
  outline: none;
}
table {
  thead {
    background-color: #edf2f8 !important;
    tr {
      height: 34px;
      th {
        font-size: 13px !important;
        white-space: nowrap;
        ._table_header {
          font-size: 13px;
          font-weight: 500;
          color: $text-secondary;
        }
        span {
          margin-left: 3px;
        }
      }
    }
  }
  tbody {
    tr {
      height: 40px;
    }
    tr:nth-of-type(even) {
      background-color: $table-even;
    }
    tr:nth-of-type(odd) {
      background-color: #ffffff;
    }
  }
  tr {
    td,
    th {
      border: 1px solid #d8e2ee;
      vertical-align: middle;
      &:last-child {
        padding-right: 20px;
        border-right: 0;
      }
      &:first-child {
        padding-left: 20px;
        border-left: 0;
      }
    }
    td {
      max-width: 300px;
      word-break: break-word;
    }
  }
}

._hyperlink {
  color: $primary !important;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
    color: $primary !important;
  }
}
.-break-all {
  word-break: break-all;
}
.-back {
  color: $secondary-text;
  &:hover {
    text-decoration: underline;
  }
}
.-p-20 {
  padding: 0px 0px 20px 0px;
}
.-logo {
  width: 110px;
  @include sm {
    width: 50%;
  }
}
.-section-header {
  font-size: 19px;
  font-weight: 500;
  color: $primary-text;
}

.-primary-text {
  color: $primary-text !important;
}

.text-right {
  text-align: right;
}
.is-invalid {
  border-color: #e6405c !important;
}
.is-valid {
  border-color: #198754 !important;
}
.ml-auto {
  margin-left: 10px;
  
}
.mr-auto {
  margin-right: auto;
}
._user_table_mobile {
  padding-bottom: 80px;
}
.react-datepicker-wrapper {
  height: 33px !important;
  .react-datepicker__input-container {
    input {
      border: 0;
      height: 33px !important;
      color: #344050;
      font-size: 13px;
      font-weight: 400;
    }
  }
}
.react-datepicker__close-icon {
  right: -26px !important;
}

.__eclips {
  display: inline-block;
  width: 90px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

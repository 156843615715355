/** 
* Change Font or do the normalization here
*/

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(./assets/fonts/Poppins-Light.ttf) format("ttf");
}

@font-face {
  font-family: "Poppins-Bold";
  font-weight: 600;
  src: local("Poppins-Bold"), url(./assets/fonts/Poppins-Bold.ttf) format("ttf");
}

@font-face {
  font-family: "Poppins-Medium";
  font-weight: 500;
  src: local("Poppins-Medium"),
    url(./assets/fonts/Poppins-Medium.ttf) format("ttf");
}
body {
  margin: 0;
  font-family: "Poppins", -apple-system, Roboto, "Helvetica Neue";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  background: #edf2f9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
#root {
  height: inherit;
}
.loaderClass {
  position: absolute;
  height: 100% !important;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #e5e5e570;
  z-index: 100;
  & > div {
    width: 30% !important;
  }
}
::-webkit-scrollbar {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #b6c2d2;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #9aa1a9;
}

@import "./colors.scss";
@import "./breakpoints";

@keyframes animationRotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(1turn);
  }
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

// Small devices
@mixin sm {
  @media (max-width: #{$screen-sm-max}) {
    @content;
  }
}

@mixin upToSm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

// Custom devices
@mixin rwd($screen) {
  @media (min-width: $screen+"px") {
    @content;
  }
}

@mixin screnRange($min, $max) {
  @media (min-width: $min + "px") and (max-width: $max+"px") {
    @content;
  }
}
